import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export interface IFetchQRCodeSettings {
  type: string;
  businessId: string;
  useBrandColors: boolean;
  includeBrandLogo: boolean;
}

export async function fetchQRCodeSettings(
  businessId: string,
  type: string
): Promise<IFetchQRCodeSettings> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
        type,
      },
    });

    return response.data.qrCodeSettings;
  } catch {
    throw Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
