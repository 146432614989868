


























import { t } from "@/i18n";
import { computed, getCurrentInstance } from "@vue/composition-api";
import { defineComponent } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { QRCodeRouteEnum, QRCodeType } from "@/v2/module/qr-code/domain";
import {
  AtomLink,
  AtomLinkSizeEnum,
  AtomLinkTypeEnum,
} from "@/v2/new-design-system";

const css = bemBuilder("qr-code-navigation");

export default defineComponent({
  name: "QRCodeNavigation",
  components: {
    AtomLink,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const activePage = computed(() => {
      const currentInstance = getCurrentInstance();
      // @ts-ignore
      return currentInstance?.proxy.$route?.name;
    });

    const isActive = (val: QRCodeRouteEnum) => activePage.value === val;

    const buttonType = (val: QRCodeRouteEnum) =>
      isActive(val) ? AtomLinkTypeEnum.DARK : AtomLinkTypeEnum.SECONDARY;

    const routes = computed(() => [
      {
        type: QRCodeType.DIGITAL_MENU,
        name: QRCodeRouteEnum.DIGITAL_MENU,
        label: t("module.qr_code.digital_menu_cta"),
      },
      {
        type: QRCodeType.DELIVERY_N_PICKUP,
        name: QRCodeRouteEnum.DELIVERY_N_PICKUP,
        label: t("module.qr_code.delivery_cta"),
      },
    ]);

    return {
      t,
      css,
      routes,
      buttonType,
      isActive,
      QRCodeType,
      QRCodeRouteEnum,
      AtomLinkSizeEnum,
    };
  },
});
