








































































































import { t } from "@/i18n";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomIcon,
  AtomInput,
  AtomLoading,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { QRCodeType, QRCodeSettingsEnum } from "@/v2/module/qr-code/domain";
import { QRCodeHeader } from "@/v2/module/qr-code/header";
import { QRCodeNavigation } from "@/v2/module/qr-code/navigation";
import { QRCodeCustomization } from "@/v2/module/qr-code/customization";
import { QRCodeList } from "@/v2/module/qr-code/list";
import {
  fetchOutlets,
  IFetchOutletsResponse,
} from "@/v2/repo/fetch-outlets/fetch-outlets";
import { fetchQRCode } from "@/v2/repo/fetch-qr-code";
import { useHelpers } from "@/v2/composable/use-helpers";
import {
  EventEnum,
  OrderingServiceOutletEnum,
  OrderingServiceStateEnum,
  OutletStateEnum,
} from "@/v2/enum";
import { fetchWidgetSettings } from "@/v2/repo/fetch-widget-settings";
import { fetchQRCodeSettings } from "@/v2/repo/fetch-qr-code-settings";
import { updateQrCodeSettings } from "@/v2/repo/update-qr-code-settings";
import { downloadImage } from "@/v2/util/download-image";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { qrCodeTrack } from "@/v2/module/qr-code/track";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("qr-code-main");

export default defineComponent({
  name: "QRCodeMain",
  components: {
    AtomIcon,
    AtomText,
    AtomInput,
    AtomButton,
    AtomLoading,
    QRCodeHeader,
    QRCodeNavigation,
    QRCodeCustomization,
    QRCodeList,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    page: {
      type: String as PropType<QRCodeType>,
      required: true,
    },
  },
  setup(props) {
    const pageLoading = ref(false);
    const qrCodeLoading = ref(false);
    const outlets = ref<IFetchOutletsResponse["data"]>([]);
    const pagination = ref<IFetchOutletsResponse["paginatorInfo"]>();
    const currentPage = ref<number>(0);
    const useBrandColors = ref(false);
    const includeBrandLogo = ref(false);
    const brandColor = ref();
    const qrCodeLogo = ref();
    const qrCodeLink = ref();

    const currentBusiness = computed(() =>
      useHelpers().getCurrentBusiness(props.businessId)
    );
    const businessLink = computed(() => {
      return `${process.env.VUE_APP_WEBVIEW_URL}/${currentBusiness.value?.url}`;
    });
    const isDigitalMenu = computed(
      () => props.page === QRCodeType.DIGITAL_MENU
    );

    const isDelivery = computed(
      () => props.page === QRCodeType.DELIVERY_N_PICKUP
    );
    const header = computed(() => {
      const titleMap: Record<string, QRCodeType> = {
        [QRCodeType.DIGITAL_MENU]: t("module.qr_code.digital_menu_header"),
        [QRCodeType.DELIVERY_N_PICKUP]: t("module.qr_code.delivery_header"),
      };
      const subtitleMap: Record<string, QRCodeType> = {
        [QRCodeType.DIGITAL_MENU]: t("module.qr_code.digital_menu_subheader"),
        [QRCodeType.DELIVERY_N_PICKUP]: t("module.qr_code.delivery_subheader"),
      };
      return {
        title: titleMap[props.page],
        subtitle: subtitleMap[props.page],
      };
    });

    const getOutletList = async (
      page: number = currentPage.value
    ): Promise<void> => {
      try {
        const result = await fetchOutlets({
          businessId: props.businessId,
          perPage: 10,
          page,
          state: [OutletStateEnum.ACTIVE, OutletStateEnum.INACTIVE],
        });
        const { data, paginatorInfo } = result || {};

        outlets.value = data || [];
        pagination.value = paginatorInfo || {};
        currentPage.value = page;
      } catch (e) {
        report(e);
      }
    };

    const getWidgetSettings = async (): Promise<void> => {
      try {
        const widgetSettings = await fetchWidgetSettings(props.businessId);
        brandColor.value = widgetSettings?.baseColor;
        qrCodeLogo.value = widgetSettings?.icon;
      } catch (e) {
        report(e);
      }
    };

    const generateQRCodeLink = async (url?: string): Promise<void> => {
      qrCodeLoading.value = true;
      try {
        let additionalData: Record<string, string | number> = {};
        if (includeBrandLogo.value && qrCodeLogo.value) {
          additionalData = {
            ...additionalData,
            logoSize: 8,
            logoURL: qrCodeLogo.value,
          };
        }
        if (useBrandColors.value) {
          additionalData = {
            ...additionalData,
            innerColor: brandColor.value,
          };
        }

        const linkMap = {
          [QRCodeType.DIGITAL_MENU]: `menus/${url}?channel=qrcode`,
          [QRCodeType.DELIVERY_N_PICKUP]: "?channel=qrcode",
        };

        qrCodeLink.value = await fetchQRCode(
          `${businessLink.value}/${linkMap[props.page]}`,
          additionalData
        );
      } catch (e) {
        report(e);
      } finally {
        qrCodeLoading.value = false;
      }
    };

    const getQRCodeSettings = async () => {
      try {
        const result = await fetchQRCodeSettings(props.businessId, props.page);
        const { includeBrandLogo: logo, useBrandColors: colors } = result;
        includeBrandLogo.value = logo;
        useBrandColors.value = colors;
      } catch (e) {
        report(e);
      }
    };

    const updateQRCodeSettings = async (
      customization: string,
      isEnable: boolean
    ) => {
      qrCodeLoading.value = true;
      try {
        await updateQrCodeSettings(
          props.businessId,
          props.page,
          customization,
          isEnable
        );

        if (props.page === QRCodeType.DELIVERY_N_PICKUP) {
          await generateQRCodeLink();
        }
      } catch (e) {
        report(e);
      } finally {
        qrCodeLoading.value = false;
      }
    };

    watch(
      () => [props.businessId, props.page],
      async () => {
        pageLoading.value = true;

        try {
          const isDeliveryPage = props.page === QRCodeType.DELIVERY_N_PICKUP;

          const requests = isDeliveryPage
            ? [getWidgetSettings, getQRCodeSettings]
            : [getOutletList, getWidgetSettings, getQRCodeSettings];

          await Promise.all(requests.map(async (req) => await req()));

          if (isDeliveryPage) {
            await generateQRCodeLink();
          }
        } finally {
          pageLoading.value = false;
        }
      },
      { immediate: true }
    );

    const onPageChange = async (page: number): Promise<void> =>
      await getOutletList(page);

    const onChangeLogoSettings = async (nextValue: boolean) => {
      const oldValue = includeBrandLogo.value;
      includeBrandLogo.value = nextValue;

      if (!qrCodeLogo.value) {
        generalErrorToast(t("module.qr_code.error_no_logo"));
        setTimeout(() => (includeBrandLogo.value = false), 0);

        return;
      }

      try {
        await updateQRCodeSettings(
          QRCodeSettingsEnum.INCLUDE_BRAND_LOGO,
          nextValue
        );
      } catch {
        includeBrandLogo.value = oldValue;
      }
    };

    const onChangeColorSettings = async (nextValue: boolean) => {
      const oldValue = useBrandColors.value;
      try {
        useBrandColors.value = nextValue;
        await updateQRCodeSettings(
          QRCodeSettingsEnum.USE_BRAND_COLORS,
          nextValue
        );
      } catch {
        useBrandColors.value = oldValue;
      }
    };

    const currentInstance = getCurrentInstance();

    const onDownload = async ({
      name,
      url,
    }: {
      name?: string;
      url?: string;
    }) => {
      await generateQRCodeLink(url);
      downloadImage(qrCodeLink.value, name || currentBusiness.value?.name);

      const eventMap = {
        [QRCodeType.DIGITAL_MENU]: "digital menu",
        [QRCodeType.DELIVERY_N_PICKUP]: "delivery / pick-up",
      };
      let additionalData: Record<string, string> = {
        clicked_on_page: eventMap[props.page],
      };

      qrCodeTrack(
        // @ts-ignore
        currentInstance?.proxy.$analytics,
        EventEnum.QR_CODE_DOWNLOAD,
        props.businessId,
        additionalData
      );
    };

    return {
      t,
      css,
      header,
      isDigitalMenu,
      isDelivery,
      useBrandColors,
      includeBrandLogo,
      outlets,
      pagination,
      pageLoading,
      qrCodeLoading,
      businessLink,
      qrCodeLink,
      onPageChange,
      onDownload,
      onChangeLogoSettings,
      onChangeColorSettings,
      QRCodeType,
      QRCodeSettingsEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      OrderingServiceOutletEnum,
      OrderingServiceStateEnum,
    };
  },
});
