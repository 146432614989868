import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function updateQrCodeSettings(
  businessId: string,
  type: string,
  customization: string,
  isEnabled: boolean
): Promise<boolean> {
  try {
    const response = await graphql.mutate({
      mutation,
      variables: {
        businessId,
        type,
        customization,
        isEnabled,
      },
    });

    return response.data.updateQrCodesForBusiness;
  } catch {
    throw Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
