












































import { t } from "@/i18n";
import { defineComponent, PropType } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomButtonLink,
  AtomButtonLinkTypeEnum,
  AtomButtonLinkSizeEnum,
  AtomSwitcher,
} from "@/v2/new-design-system";

const css = bemBuilder("qr-code-customization");

export default defineComponent({
  name: "QRCodeCustomization",
  components: {
    AtomButtonLink,
    AtomText,
    AtomSwitcher,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    includeLogo: {
      type: Boolean,
      required: true,
    },
    useBrand: {
      type: Boolean,
      required: true,
    },
    onIncludeLogoChange: {
      type: Function as PropType<(state: boolean) => void>,
      required: true,
    },
    onUseBrandChange: {
      type: Function as PropType<(state: boolean) => void>,
      required: true,
    },
  },
  setup() {
    const actionTextType = (val: boolean): AtomTextTypeEnum =>
      val ? AtomTextTypeEnum.BODY_BOLD : AtomTextTypeEnum.BODY;

    const actionTextColor = (val: boolean): AtomTextColorEnum =>
      val ? AtomTextColorEnum.MIDNIGHT : AtomTextColorEnum.RAINY;

    return {
      t,
      css,

      actionTextType,
      actionTextColor,

      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonLinkTypeEnum,
      AtomButtonLinkSizeEnum,
    };
  },
});
