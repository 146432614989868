var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},_vm._l((_vm.routes),function(ref,index){
var _obj;

var name = ref.name;
var label = ref.label;
var type = ref.type;return _c('AtomLink',{key:index,class:[
      _vm.css('navigation'),
      ( _obj = {}, _obj[_vm.css('navigation', 'active')] = _vm.isActive(name), _obj ) ],attrs:{"type":_vm.buttonType(name),"size":_vm.AtomLinkSizeEnum.REGULAR,"data-test":type,"route":{
      name: name,
      params: {
        businessId: _vm.businessId,
      },
    }}},[_vm._v(" "+_vm._s(label)+" ")])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }