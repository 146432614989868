




































import { t } from "@/i18n";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomButtonLink,
  AtomButtonLinkSizeEnum,
  AtomText,
  AtomTextColorEnum,
  MolPagination,
} from "@/v2/new-design-system";
import { IFetchOutletsResponse } from "@/v2/repo/fetch-outlets/fetch-outlets";

const css = bemBuilder("qr-code-list");

export default defineComponent({
  name: "QRCodeList",
  components: {
    AtomText,
    AtomButtonLink,
    MolPagination,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outlets: {
      type: Array as PropType<IFetchOutletsResponse["data"]>,
      required: true,
    },
    pagination: {
      type: Object as PropType<IFetchOutletsResponse["paginatorInfo"]>,
      required: true,
      default: () => ({}),
    },
    onPageChange: {
      type: Function as PropType<(page: Number) => Promise<void>>,
      required: true,
    },
  },
  setup(props) {
    const list = computed(() => {
      const { outlets } = props;
      if (outlets.length) {
        return outlets.map(({ orderingServices, ...outlet }) => {
          const services = orderingServices?.reduce(
            (result, item) => ({ ...result, [item?.slug]: item?.state }),
            {}
          );
          return { ...outlet, ...services };
        });
      }

      return outlets;
    });

    return {
      t,
      css,
      list,
      AtomTextColorEnum,
      AtomButtonLinkSizeEnum,
    };
  },
});
