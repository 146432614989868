export const downloadImage = (link: string, name: string): void => {
  const image = new Image();
  image.crossOrigin = "";
  image.src = link;
  image.onload = function (): void {
    const canvas = document.createElement("canvas");
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    canvas.getContext("2d")!.drawImage(image, 0, 0);
    canvas.toBlob(function (blob) {
      if (!blob) {
        throw new Error("Download image: blob not provided");
      }

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${name.toLowerCase().replace(/ /g, "_")}.png`;
      a.click();

      window.URL.revokeObjectURL(url);
    }, "image/png");
  };
};
