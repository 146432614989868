

















import { defineComponent } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  MolGuideLink,
  MolGuideLinkArticleEnum,
} from "@/v2/new-design-system";

const css = bemBuilder("qr-code-header");

export default defineComponent({
  name: "QRCodeHeader",
  components: {
    AtomText,
    MolGuideLink,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      MolGuideLinkArticleEnum,
    };
  },
});
